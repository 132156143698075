<template>

    <div class="map box">

        <LocalSpinner :active="spinner_active"/>

        <div class="map__left">
            <!-- <img :src="require('@/assets/map_static.png')"/> -->
              <GMapMap
                    :zoom="7"
                    :center="center"
                    style="width: 100%; height: 100%"
                    :options="mapStyle"
                    ref="gmapref"
                >

                    <GMapCluster 
                         :zoomOnClick="true"
                         :minimumClusterSize="20" 
                         :styles="clusterStyles"
                    >
                        <GMapMarker
                            :key="index"
                            v-for="(m, index) in map_coords"
                            :position="{ lat : m.latitude, lng : m.longitude }"
                            :clickable="true"
                            @click="openMarker(index)"
                            :draggable="false"
                            :icon="mapIcon"
                            
                        >
                            <GMapInfoWindow :opened="openedMarkerID === index">
                                <div class="map__infowindow">
                                    <span>{{active_trade.name}}</span>
                                    <p>{{m.title}}</p>
                                </div>
                            </GMapInfoWindow>
                        </GMapMarker>
                    </GMapCluster>
                </GMapMap>

                <div class="small_screen_amount">
                    <br>
                    <label>Antal opgaver (365 dage)</label>
                    <div class="map__amount">
                        <AnimatedNumber :number="trades_count" :hide_currency="true" />
                    </div>
                </div>
        </div>
        <div class="map__right">

            <div class="map__intro">
                <h4>Se tidligere opgaver</h4><br>
                Vælg en branche og se hvor opgaverne er kommet fra de sidste 365 dage.</div>

            <div class="field">
         
                <label for="choose_trade">Se relevante opgaver</label>
                <VueMultiselect
                    v-if="trades"
                    v-model="active_trade"
                    :options="trades"
                    :close-on-select="true"
                    :clear-on-select="false"
                    placeholder="Vælg branche"
                    label="name"
                    :show-labels="false"
                    track-by="name"
                />
            </div>

            <br>
            <div class="large_screen_amount">
                <label>Antal opgaver (365 dage)</label>
                <div class="map__amount">
                    <AnimatedNumber :number="trades_count" :hide_currency="true" />
                </div>
            </div>

        </div>

        

    </div>

</template>

<script>
import VueMultiselect from 'vue-multiselect'
import AnimatedNumber from './../components/AnimatedNumber.vue'
import LocalSpinner from './../components/LocalSpinner.vue'
export default {
    components: {AnimatedNumber, VueMultiselect, LocalSpinner},
    data() {
        return {
            openedMarkerID: null,
            active_trade: [],
            map_coords: [],
            trades_count: 0,
            spinner_active: 0,

            // Map options
            center: {lat: 56.2250145, lng: 10.4217454},
            mapIcon: {
                url: require("@/assets/icons/mapmarker.svg"),
                scaledSize: {width: 10, height: 10},
                labelOrigin: {x: 5, y: -5}
            },
          
            clusterStyles: [
                {
                    textColor: "white",
                    url: require("@/assets/icons/mapmarker.svg"),
                    height: 35,
                    width: 35,
                    textSize: 14
                }
            ],

            mapStyle:  {
                disableDefaultUI: true,
                zoomControl: true,
                scaleControl: true,
                maxZoom: 14,
                minZoom: 6,
                styles: [{"featureType":"administrative","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#E4EAF8"},{"visibility":"on"}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"visibility":"on"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45},{"visibility":"off"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"transit.station","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#FFFFFF"},{"visibility":"on"}]}]
            }
            
        }
    },

    watch: {

        active_trade: function (newTrade, oldTrade) {
            this.updateMap()
        }
    },

    computed: {
        trades() {
            return this.$store.getters.trades
        }
    },

    methods: {

        openMarker(id) {
            this.openedMarkerID = id
        },


        updateMap() {
            
            if(this.active_trade?.id === undefined) return false;

            let id = parseInt(this.active_trade.id)
            this.spinner_active = true

            if( isNaN(id) ) return false;

            this.axios.get("https://api.3byggetilbud.dk/websites/pro/projects/heatmap?trade=" + id).then( (response) => {
                this.map_coords = response.data.coordinates
                this.trades_count = response.data.count

                this.spinner_active = false
            })
        }
    },

    mounted() {


        var map = this.$refs.gmapref;
        
        map.$mapPromise.then((map) => {

            // set bounds of denmark - Hardcode values to prevent too many $$ calls to google maps
             var bounds = new google.maps.LatLngBounds(
                 new google.maps.LatLng(54.464597, 8.072241),
                 new google.maps.LatLng(57.751813, 15.197281) );

             map.fitBounds(bounds);
                 map.addListener('click', (mapsMouseEvent) => {
                        this.openMarker(null)
                });
        })
        
    },

    created() {
        this.$store.dispatch('get_trades')
    }

}
</script>


<style lang="scss">
  
  .map {

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
    position: relative;
    height: 100%;

    &__left {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;

        .vue-map-container {
            height: 100%;
            height: 520px;
            div a,
            .gmnoprint:not([data-control-width]),
            .gm-style-cc {
                display: none!important;
            }

        }

       .cluster img {
            width: 100%;
            top: -9.5px;
        }
    }

    &__right {
        flex: 0 0 280px;
        padding-left: 2rem;
    }

    &__intro {
        font-size: 1rem;
        margin-bottom: 2rem;
    }

    &__amount {
        background: $color8;
        color: white;
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
        padding: 2rem 0;
        border-radius: $radius;
    }

    &__infowindow {
        max-width: 200px;
        padding: 9px;
        span {
            position: relative;
            color: $color8;
            padding: 4px 10px;
            border-radius: 40px;
            border: 1px solid $color8;
            display: inline-block;
            margin-bottom: 0.9rem;
            font-weight: 500;
            left: -3px;
        }
        p {
            font-weight: bold;
            margin: 0;
            line-height: 1.1;
        }
    }

    .gm-ui-hover-effect {
        top: 0px!important;
        right: 0px!important;
        img {
            width: 18px!important;
            height: 18px!important;
            margin: 4px!important;
        }
    }

  }


  .large_screen_amount { display: block; }
  .small_screen_amount { display: none; }

  @media( max-width: 2000px ) and ( min-width: 1499px ), ( max-width: 800px ) {
        .large_screen_amount { display: none; }
        .small_screen_amount { display: block; }


        .map {
            flex-direction: column;
            height: auto;
              &__left {
                  order: 2;
                  .vue-map-container {
                      height: 450px;
                  }
              }
              &__right {
                 flex: auto;
                 order: 1;
                 padding-left: 0rem;
              }
        }
  }

    @media( max-width: 599px )  {
     
        .map__left .vue-map-container {
                height: 300px;
        }    
    }


    .multiselect__tags {
        min-height: 40px;
        padding: 15px 40px 5px 8px;
        border: 1px solid #889bd6;
        font-size: 16px;
    }

    .multiselect__select {
        height: 46px;
    }

    .multiselect__placeholder {
        line-height: 1.1;
        padding-left: 5px;
    }
</style>